import React, { Suspense, useContext } from 'react'
import {connect} from 'react-redux';
import { Helmet } from "react-helmet";
import {Switch, Route, Redirect, useLocation } from "react-router-dom";

import { setUserData } from 'actions/user'
import { deleteAllCookies } from 'utils/cookie'
import { routes } from 'configurations/routes';
import 'assets/sass/style.module.scss'
import NotFound from 'containers/NotFound/NotFound';
import 'alerty.css'
import 'assets/theme.css'
import checkAuth from './utils/checkAuth'
import ContextWorker from './contextWorker'
import { GlobalContext } from './context'
import { get } from 'lodash';
import {
	REGION_CODE_05,
	REGION_CODE_105, REGION_CODE_116,
	REGION_CODE_16,
	REGION_CODE_40,
	REGION_CODE_50,
	REGION_CODE_72
} from "./constants";
import {regionConfigValue} from "./utils/regionConfigValue";

export const redirectPath = () => {
	if (window.location.origin.includes('mosreg')) {
		return `/${REGION_CODE_50}`;
	} else if (window.location.origin.includes('admoblkaluga')) {
		return `/${REGION_CODE_40}`
	} else if (window.location.origin.includes('16')) {
		return `/${REGION_CODE_16}`
	} else if (window.location.origin.includes('05')) {
		return `/${REGION_CODE_05}`
	} else if (window.location.origin.includes('72')) {
		return `/${REGION_CODE_72}`
	} else if (window.location.origin.includes('105')) {
		return `/${REGION_CODE_105}`
	} else if (window.location.origin.includes('116')) {
		return `/${REGION_CODE_116}`
	} else {
		return '/';
	}
}

const renderLoader = () => <p>.</p>;
export const MetaTag = ({ region }) => {
	ContextWorker()
	const { context } = useContext(GlobalContext);
	let globalName = get(context, 'globalName');
	const config = regionConfigValue();

	if (region) {
		globalName = 'Моя школа';

		if (region === REGION_CODE_16 || region === REGION_CODE_116) {
			globalName = 'Электронное образование Республики Татарстан (Моя школа)';
		}

		return (
			<Helmet>
				<meta charSet="utf-8" />
				<title>{globalName}</title>
				<meta
					name="description"
					content={`«${globalName}» - для учителей, учеников и родителей. Помогает качественно учить, успешно учиться и эффективно следить за образовательной деятельностью ребенка в школе. Передовые технологии делают обучение доступным, предлагая школьникам современные форматы работы.`}
				/>
			</Helmet>
		)
	}

	return (
		<Helmet>
			<meta charSet="utf-8" />
			<title>{globalName}</title>
			{(config?.USE_KASPERSKY && config?.SERVICE_URL === 'https://school-test.mos.ru') && <script src="/das.obf.test.js" async />}
			{(config?.USE_KASPERSKY && config?.SERVICE_URL === 'https://school.mos.ru') && <script src="/das.obf.prod.js" async />}
			<meta
				name="description"
				content={`«${globalName}» — это проект для учителей, детей и родителей, направленный на создание высокотехнологичной образовательной среды в школах`}
			/>
		</Helmet>
	)
}

export class PublicRoute extends React.Component {
	publicAccess(props) {
		const { component: Component } = this.props;
		const environment = process.env.REACT_APP_STAGE
		return (
			<Suspense fallback={renderLoader()}>
				<MetaTag />
				<Component {...props} environment={environment} />
			</Suspense>
		)
	}

	render() {
		return <Route render={props => this.publicAccess(props)} />
	}
}

export class PrivateRoute extends React.Component {
	privateAccess(props) {
		const { component: Component } = this.props;
		const environment = process.env.REACT_APP_STAGE
		return (
			<Suspense fallback={renderLoader()}>
				<MetaTag />
				<Component {...props} environment={environment} />
			</Suspense>
		)
	}

	render() {
		return checkAuth() ? <Route render={props => this.privateAccess(props)} /> : <Redirect to={redirectPath()} />
	}
}

class App extends React.Component {

	render() {
		return (
			<Switch>
				{
					routes.map((route, i) => route?.private ?
						<PrivateRoute key={i} {...route} /> :
						<PublicRoute key={i} {...route} />
					)}
				<Redirect
					from="/logout"
					to="/"
					render={() => deleteAllCookies() }
				/>
				<Route
					path="*"
					component={NotFound}
				/>
			</Switch>
		);
	}
}

const actionCreators = {
	setUserData,
}

export default connect(
	null,
	actionCreators
)(App);
